import { Link } from "react-router-dom"
import CardWithDecorativeImage from "./components/Card"
import events from "./events/events.json"
export default function Gallery() {
    return(              
        <div className='mx-8 my-8 py-8 px-8 animate__animated   animate__fadeIn flex gap-10 max-lg:flex-wrap'>
            {
                events.map((event) => (
                    event.eventPhotos.length > 0 &&
                    <Link to={`/gallery/${event.eventID}`}><CardWithDecorativeImage event={event}/></Link>
                ))
            }
        
      </div>
                
    )
    
}
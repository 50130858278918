import React from 'react'
import CarouselHero from './components/CarouselHero'
import 'animate.css';
import { Button } from 'flowbite-react';
const Home = () => {
  return (
    <div>

    <div className='flex mx-auto mt-8 mb-16 max-lg:flex-col'>
    <div className='flex items-center justify-center lg:w-1/3 animate__animated animate__fadeInLeft'>
     <img src="/images/hp1.png" alt="..." width={250}/>
    </div>
    <div className='flex flex-col items-center justify-center gap-4 lg:w-1/3 animate__animated animate__zoomIn z-50'>
         <h1 className='max-lg:text-3xl text-5xl flex  items-center justify-center'>Welcome to Indutsava</h1>
         <div className='max-lg:text-2xl text-3xl text-green-500 flex max-lg:flex-col items-center justify-center gap-4'>
          <p className='text-orange-500'>Connect</p>
          <p className='text-blue-500'>Create</p>
          <p className='text-green-500'>Cherish</p>
         </div>
         
    </div>
    <div className=' lg:w-1/3 animate__animated animate__fadeInRight flex items-center justify-center '>
     <img src="/images/hp2.png" alt="..." width={400} height={400}/>
    </div>
    </div>
    <CarouselHero/>
    <div className='flex max-lg:flex-col items-center justify-center gap-20 max-lg:gap-5  animate__animated animate__fadeIn'>
            <Button href="/events" className='bg-orange-500 w-48'>Explore Events</Button>
            <Button href="/gallery" className='bg-slate-200 text-blue-700 w-48'>View Gallery</Button>
            <Button href="/member" className="bg-green-500 w-48">Become a member</Button>
    </div>
            
    </div>
  )
}

export default Home

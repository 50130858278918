import PhotoAlbum from "react-photo-album";
import ImageGallery from "react-image-gallery";
import { useParams } from "react-router-dom";
import { Button } from "flowbite-react";
import "animate.css"
import {useState, useEffect} from "react";
import events from "../events/events.json"



export default function EventGallery() {
  const {id} = useParams()
  const [currentEvent, setCurrentEvent] = useState({})
  useEffect(() => {
    events.forEach(ev =>{
      if( ev.eventID === id){
        setCurrentEvent(ev)
      }
    })
  },[id])
    try{
      return(   
      <>           
              <h1 className="mt-8 mb-4 mx-8 max-lg:text-2xl text-4xl">Photos of {currentEvent.eventName} </h1>


              <div className=" mx-16 my-16">
                   <ImageGallery items={currentEvent.eventPhotos || 
                    [{original: "/images/gc/gc6.jpeg",thumbnail:"/images/gc/gc6.jpeg"}]
                   } />
                   
               </div>
               <div className="mx-8 flex max-lg: flex-wrap items-center justify-center gap-4">
               <p className="max-lg:text-sm text-md text-slate-500">We would love to have you in our next event</p>
               <Button href="/member" className="bg-green-500 w-48 text-sm">Become a member</Button>
             </div>

      </>
              
  )

    }catch(err){
      return (
        <p>Loading</p>
      )
    }
    
    
}
import { Carousel } from 'flowbite-react';
import { Button } from 'flowbite-react';
 
export default function CarouselHero() {
  return (
    <>
    {/* <h1>Test</h1>
    <Carousel>
      <div className="flex h-full items-center justify-center bg-gray-400 dark:bg-gray-700 dark:text-white">
        Slide 1
      </div>
      <div className="flex h-full items-center justify-center bg-gray-400 dark:bg-gray-700 dark:text-white">
        Slide 2
      </div>
      <div className="flex h-full items-center justify-center bg-gray-400 dark:bg-gray-700 dark:text-white">
        Slide 3
      </div>
    </Carousel>
  <Button>Click me</Button>
  <h1>Test</h1> */}
  </>
  );
}
import React from 'react';
import 'animate.css';
const About = () => {
  return (
    <div className='mx-8 text-left my-16   animate__animated animate__fadeIn'>
          <header>
        <h1 className='max-lg:text-2xl text-6xl font-semibold'>Welcome to Indutsava</h1>
        <p className='max-lg:text-xl text-3xl'>Your Gateway to India in Belgium!</p>
    </header>
    <div className='bg-gradient-to-r from-orange-100 via-slate-50  to-green-100 p-4 my-8 rounded-lg shadow-md'>
    <section id="about" className='text-left my-8'>
        {/* <h2>About Us</h2> */}
        <p>
            <strong>Indutsava </strong>is a vibrant and close-knit Indian community based in the heart of Antwerp, Belgium. We are a group of passionate individuals who share a deep love for the rich culture, traditions, and festivities of India. Our community is a melting pot of diverse backgrounds, bringing together people from all corners of India, who now call Belgium their home.
        </p>
    </section>

    <section id="mission">
        <h2 className='max-lg:text-xl  font-semibold text-3xl my-8'>Our Mission</h2>
        <p>
            At Indutsava, our mission is to foster a sense of togetherness, preserve our Indian heritage, and celebrate the beauty of India's festivals and traditions right here in Belgium. We believe in creating a warm and welcoming environment where everyone, regardless of their background, can come together to share in the joy and festivities that India has to offer.
        </p>
    </section>

    <section id="what-we-do">
        <h2 className='max-lg:text-xl  font-semibold text-3xl my-8'>What We Do</h2>
        <p>
            Indutsava hosts a wide range of events and celebrations throughout the year, mirroring the vibrant tapestry of India. From the colorful and lively festivals of Diwali, Holi, Eid, and Christmas to cultural exhibitions, workshops, and culinary delights, we offer a diverse array of experiences that allow you to immerse yourself in the spirit of India.
        </p>
    </section>

    <section id="our-community">
        <h2 className='max-lg:text-xl  font-semibold text-3xl my-8'>Our Community</h2>
        <p>
            Our community is more than just a group of individuals; it's a family. We cherish the bonds we create and the friendships we forge. Whether you're a new arrival in Belgium seeking a taste of home or a long-time resident looking to connect with like-minded individuals, you'll find a warm and open-hearted community here at Indutsava.
        </p>
    </section>

    <section id="get-involved">
        <h2 className='max-lg:text-xl  font-semibold text-3xl my-8'>Get Involved</h2>
        <p>
            Indutsava is open to everyone who shares an interest in Indian culture, traditions, and festivals. Whether you're a seasoned festival-goer or just curious about what India has to offer, we invite you to become a part of our community. Join us in celebrating the diversity and beauty of India right here in Belgium.
        </p>
    </section>

    <section id="contact">
        <h2 className='max-lg:text-xl  font-semibold text-3xl my-8'>Contact Us</h2>
        <p>
            We'd love to hear from you and welcome you into our Indutsava family. If you have any questions, suggestions, or would like to get involved, please don't hesitate to reach out to us. We're here to connect, celebrate, and create unforgettable memories together.
        </p>
    </section>

    <footer>
        <p>Thank you for visiting Indutsava – Your home away from home for all things Indian in Belgium.</p>
    </footer>
    </div>
    </div>
  )
}

export default About

import { Button, Card } from 'flowbite-react';
import { FcCalendar,FcAutomotive } from 'react-icons/fc';

export default function CardWithDecorativeImageEvent({event}) {
  return (
    <Card
      imgAlt="..."
      imgSrc={event.eventCardImage}
      className='w-80'
    >
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        <p>
          {event.eventName}
        </p>
        
      </h5>
      <div className='flex-col gap-20 justify-between'>
      <p className='flex items-center justify-left gap-4 text-sm my-4'><FcCalendar size={23}/>{" "+event.eventstartDate}</p>
      <p className='flex items-center justify-left gap-4 text-sm'><FcAutomotive size={35}/>{" "+event.eventLocation}</p>
      </div>
      
      <Button className='bg-green-500'>Check Event</Button>
    </Card>
  )
}
import React from 'react'
import { Navbar } from 'flowbite-react';
import { Link } from 'react-router-dom';


export default function DefaultNavbar() {
  return (
    <Navbar
      fluid
      rounded
      className='bg-gradient-to-r from-orange-500 via-blue-200  to-green-500 z-100 text-slate-700'
    >
      <Navbar.Brand
        href="/"
      >
        <img
          alt="..."
          className="mr-3 h-6 sm:h-9"
          src="/images/idslogo.png"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Indutsava
        </span>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Navbar.Link
          href="/member"
        >
          <p className="text-slate-900">
            Become a Member
          </p>
        </Navbar.Link>
        <Navbar.Link href="/events">
           <p className="text-slate-900">Events</p> 
        </Navbar.Link>
        <Navbar.Link href="/gallery">
        <p className="text-slate-900">Gallery</p> 
        </Navbar.Link>
        <Navbar.Link href="/about">
        <p className="text-slate-900">About Us</p> 
        </Navbar.Link>
        <Navbar.Link href="/contact">
        <p className="text-slate-900">Contact Us</p> 
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  )
}



import React from 'react';
import "animate.css";

const formConfig = {
    loadingText: "laoding form",
    formURL: (
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfI5CzFHlVFADyPvr1OxQRW9A6iUHzSe6mMFOaWCKKMnNi6iA/viewform?embedded=true"
        width="640"
        height="947"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    )
  };
const Member = () => {

  return (
    <div className='  animate__animated animate__fadeIn'>

    <h1 className='mt-8 mx-8 p-4 text-4xl bg-orange-500 text-slate-50'>
       Please fill out the form if you want to become a member
    </h1>
    <div className='mb-8 mx-8 flex items-center justify-center bg-slate-200'>
      
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdn5bIE71AoNdcGUjGbx4ugfuH3SVoJO6VIP3nKOrcpZgbEmw/viewform"
        width="840"
        height="947"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </div>
    </div>
  )
}

export default Member

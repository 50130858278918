import React,{useEffect, useState} from 'react'
import { Button, Timeline } from 'flowbite-react';
import { useParams } from 'react-router-dom';
import { HiCalendar } from 'react-icons/hi';
import events from "../events/events.json"
const EventDetails = () => {
    const {id} = useParams()
    const [event,setEvent] = useState()
    useEffect(() => {
        events.forEach(ev =>{
            if( ev.eventID === id){
                setEvent(ev)
            }
          })
    }, [id]);

    try {
        return (
            <div className="m-16 flex flex-col  items-center text-start">
                <h1 className='max-lg:text-2xl text-4xl my-8'>Schedule for {event.eventName}</h1>
              <Timeline>
                {
                    event.eventDetails.map((detail) => (
                        <Timeline.Item key={Math.random()}>
                            <Timeline.Point  icon={HiCalendar}/>
                             <Timeline.Content>
                                 <Timeline.Time>{detail.eventDate+", "+detail.eventDay}</Timeline.Time>
                                 <Timeline.Title>{detail.eventHeader}</Timeline.Title>
                                 
                                            <Timeline.Body>
                                        {
                                            detail.eventDetails.map((subevent) => (
                                                <div className='p-4'>
                                                    <p className='max-lg:text-md text-lg'>{subevent.eventStep+" "+subevent.eventStartTime}</p>
                                                    <p className='max-lg:text-sm text-md text-slate-400'>{subevent.eventHighlights}</p>
                                                </div>
                                            ))
                                        }
                                 </Timeline.Body>
                              </Timeline.Content>
                     </Timeline.Item>
                    ))
                }
               
              </Timeline>
            </div>
          )
    }catch(err){
        return(
            <>Loading</>
        )
    }
  
}

export default EventDetails

import { Card } from 'flowbite-react'
import React from 'react'
import CardWithDecorativeImageEvent from './components/CardEvent'
import 'animate.css';
import { Link } from 'react-router-dom';
import { useState,useEffect } from 'react';
import events from "./events/events.json"


const Events = () => {
    const [upcoming,setUpcoming] = useState([])
    const [ongoing,setOngoing] = useState([])
    const [recent,setRecent] = useState([])
    useEffect(() => {
        const rec = events.filter((t) => (t["eventStatus"] === 'recent'))
        console.log("reecent", rec)
        setRecent(rec || [])
        const upc = events.filter((t) => (t["eventStatus"] === 'upcoming'))
        console.log("upc", upc)
        setUpcoming(upc || [])
        const ong = events.filter((t) => (t["eventStatus"] === 'ongoing'))
        console.log("ong", ong)
        setOngoing(ong || [])
    },[])
    try{
        return (
            <>
            <div className='mx-8 my-8 px-8 animate__animated   animate__fadeIn flex-col max-lg:flex-wrap gap-10  items-center justify-center'>
            {
               ongoing.length > 0 &&
           
                <h1 className='text-3xl font-semibold mb-8 text-center'>Ongoing events</h1>
            }
            <div className='flex justify-center items-center gap-10 max-lg:flex-wrap'>
             {
               ongoing.length > 0 &&
               (ongoing.map((event) => (
                  <Link to={`/events/${event.eventID}`}> <CardWithDecorativeImageEvent event={event}/></Link> 
                )))
            }
            </div>
              
            </div>
            
                <div className='mx-8 my-8 py-8 px-8 animate__animated   animate__fadeIn flex-col max-lg:flex-wrap gap-10  items-center justify-center'>
                {
                    upcoming.length > 0 &&
                
                        <h1 className='text-3xl font-semibold mb-8 text-center'>Upcoming events</h1>
                }
                <div className='flex justify-center items-center gap-10 max-lg:flex-wrap'>
                {
                  upcoming.length > 0 &&  upcoming.map((event) => (
                         <Link to={`/events/${event.eventID}`}> <CardWithDecorativeImageEvent event={event}/></Link> 
                   ))
                    
                }
                 </div>
               </div>
                   <div className='mx-8 my-8 py-8 px-8 animate__animated   animate__fadeIn flex-col max-lg:flex-wrap gap-10 items-center justify-center'>
                   {
                    recent.length > 0 &&
                
                      <h1 className='text-3xl font-semibold mb-8 text-center'>Recent events</h1>
                    }
                   
                  <div className='flex justify-center items-center gap-10 max-lg:flex-wrap'>
                   {
                     recent.length > 0 &&
                     recent.map((event) => (
                        <Link to={`/events/${event.eventID}`}> <CardWithDecorativeImageEvent event={event}/></Link> 
                      ))
                   }
                </div>
                  </div>
                  </>
          )
    }catch(err){
        return(
            <p>Loading {err.toString()}</p>
        )
    }
  
}

export default Events

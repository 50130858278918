import { Button, Card } from 'flowbite-react';
import {FcOldTimeCamera} from 'react-icons/fc';
export default function CardWithDecorativeImage({event}) {
  return (
    <Card
      imgAlt="..."
      imgSrc={event.eventCardImage}
      className='w-72'
    >
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        <p>
          {event.eventName}
        </p>
      </h5>
      <p className='flex items-center justify-left gap-4'><FcOldTimeCamera size={23}/>{"  "+event.eventPhotos.length+" photos"}</p>
      
      <Button className='bg-orange-500'>Check photos</Button>
    </Card>
  )
}